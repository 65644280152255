import React, { useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { send } from 'emailjs-com'
import emailjs from 'emailjs-com'

const Form = () => {
  const { t } = useTranslation()
  const { register, handleSubmit, reset, errors } = useForm()
  const [isClicked, setIsClicked] = useState(false)

  const [toSend, setToSend] = useState({
    from_name: '',
    email: '',
    phone_number: '',
    question: '',
  })

  const form = useRef()

  const SERVICE_ID = 'service_rcyyxub'
  const TEMPLATE_ID = 'template_50dmnjq'
  const PUBLIC_KEY = 'JxWpsJkkcAzcq28E8'

  const onSubmit = (data) => {
    setIsClicked(true)

    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
      .then((result) => {
        console.log('SUCCES!')
      })
      .catch((err) => {
        console.log('FAILED...', err)
      })
    reset()
  }

  return (
    <div className="">
      <form
        ref={form}
        className="h-100 border-provence border-4 pb-10 pt-5 rounded-xl w-[350px] md:ml-auto md:mr-auto md:p-5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col items-center ">
          <div className="text-center mt-4 ">
            <input
              type="text"
              placeholder={t('first-name')}
              name="firstName"
              className="border rounded w-[250px] caret-provence"
              {...register('firstName', { required: true })}
            />
          </div>
          <div className="text-center mt-4">
            <input
              className="border rounded w-[250px] caret-provence"
              type="text"
              placeholder={t('last-name')}
              name="lastName"
              {...register('lastName', { required: true })}
            />
          </div>
          <div className="text-center mt-4">
            <input
              type="email"
              placeholder=" email"
              name="email"
              className="border rounded w-[250px] caret-provence"
              {...register('email', { required: true })}
            />
          </div>
          <div className="text-center mt-4">
            <input
              type="text"
              placeholder={t('phone-number')}
              name="phone"
              className="border rounded w-[250px] caret-provence"
              {...register('phone', { required: true })}
            />
          </div>
          <div className="text-center mt-4">
            <textarea
              className="border h-[100px] rounded w-[250px] mb-6 caret-provence"
              type="text"
              placeholder={t('questions')}
              name="question"
              {...register('question')}
            />
          </div>

          <div className="text-center w-[170px] ml-auto mr-auto border py-2 bg-provence text-white font-bold font-text hover:bg-blue-400 rounded">
            <input type="submit" />
          </div>
          {isClicked && (
            <>
              <div className="mt-3">Message has been send</div>
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export default Form

import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Navbar from "./components/Navbar";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Photos from "./pages/Photos";
import Rent from "./pages/Rent";
import Sivergues from "./pages/Sivergues";
import Footer from "./components/Footer";
import AdminHome from "./pages/admin/AdminHome";
// import NavbarAdmin from './components/admin/NavbarAdmin'
import Success from "./pages/Success";
import Fail from "./pages/Fail";
import Pay from "./pages/Pay";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const App = () => {

  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sivergues" element={<Sivergues />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/photos" element={<Photos/>} />
          <Route path="/rent" element={<Rent/>} />
          <Route path="/admin" element={<AdminHome/>} />
          <Route path="/pay" element={<Pay/>} />
          <Route path="/paymentAccepted" element={<Success/>} />
          <Route path="/paymentDeclined" element={<Fail/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;

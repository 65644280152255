import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

const LanguageSwitcher = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const currentLanguage = i18n.language;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('lang', lng)
    setIsOpen(false);
  };

  return (
    <>
      <div className="relative inline-block">
        <button
          className="text-sm border-1 border-provence font-medium font-text rounded-md outline-none ring-2 ring-offset-2 ring-offset-gray-100 ring-provence px-4"
          onClick={toggleMenu}
        >
          {t(`language.${currentLanguage}`)}
        </button>
        {isOpen && (
          <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 sm:w-48 md:w-64">
            <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <button
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-right md:text-left"
                onClick={() => changeLanguage("en")}
                disabled={currentLanguage === "en"}
              >
                {t("language.english")}
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-right md:text-left"
                onClick={() => changeLanguage("fr")}
                disabled={currentLanguage === "fr"}
              >
                {t("language.français")}
              </button>
              <button
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 w-full text-right md:text-left"
                onClick={() => changeLanguage("nl")}
                disabled={currentLanguage === "nl"}
              >
                {t("language.nederlands")}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LanguageSwitcher;

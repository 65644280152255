import axios from "axios";

const api = axios.create({
  baseURL: "https://sivergues-api.osc-fr1.scalingo.io",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": [
      "https://sivergues-client-git-master-samuelroeland.vercel.app",
      "https://www.lesgrottes-sivergues.com",
    ],
  },
});

export default api;

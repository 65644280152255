import React from 'react'
import { useState, useEffect } from 'react'
import Booking from '../../components/admin/Booking'
import '../admin/AdminHome.css'
import { AiOutlineReload } from 'react-icons/ai'
import Calendar from '../../components/Calendar'
import api from '../../api'

const AdminHome = () => {
  const [bookings, setBookings] = useState([])
  const [rerender, setRerender] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)

  useEffect(() => {
    getBookings()
  }, [bookings.length])

  const getBookings = async () => {
    try {
      let res = await api.get(`${process.env.REACT_APP_API_URL}api/bookings`)

      setBookings(res.data)
    } catch (err) {
      console.log(err)
    }
  }

  const confirmBooking = async (bookingId) => {
    try {
      await api.patch(
        `${process.env.REACT_APP_API_URL}api/bookings/${bookingId}`,
        {
          bookingConfirmed: true,
        },
      )

      setIsConfirmed(true)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="container mb-[100px]">
      <div
        className="text-xl m-10 cursor-pointer flex"
        onClick={() => {
          setRerender(!rerender)
        }}
      >
        All Bookings: <AiOutlineReload className="ml-4 w-7 h-7" />
      </div>

      <div className="">
        <div className="">
          <div className="">
            <div className="grid grid-cols-2 gap-3">
              {bookings.map((booking) => {
                return (
                  <Booking
                    key={booking._id}
                    props={booking}
                    isConfirmed={isConfirmed}
                    confirmBooking={confirmBooking}
                    className="border-blue-600"
                  />
                )
              })}
            </div>
          </div>
        </div>
        {/* <div className="col-start-2 ml-10">
          <Calendar dates={dates} />
        </div> */}
      </div>
    </div>
  )
}

export default AdminHome

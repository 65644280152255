import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const Success = () => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)

  // use this to update booking
  const payment_intent = params.get('payment_intent')

  useEffect(() => {})

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-provence">
      <div className="bg-white rounded-xl w-full md:w-1/2 lg:w-1/3 max-w-md px-8 py-12">
        <p className="font-bold text-center text-xl font-text">THANK YOU</p>
        <p className="text-black text-lg text-center mt-5 font-text">
          Your payment has been accepted.
        </p>
        <p className="text-center text-lg mt-2 font-text">
          We hope you have a wonderful stay at{' '}
          <span className="font-logo">Les Grottes.</span>
        </p>
        <div className="mt-10 flex justify-center space-x-4">
          <a
            className="text-center text-provence font-bold hover:text-white hover:bg-provence px-6 py-2 rounded-lg"
            href="/contact"
          >
            Contact the host
          </a>
          <a
            className="text-center text-provence font-bold hover:text-white hover:bg-provence px-6 py-2 rounded-lg"
            href="/"
          >
            Back to home page
          </a>
        </div>
      </div>
    </div>
  )
}

export default Success

import React, { useEffect, useState } from 'react'

import { AiOutlineCheck, AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai'

const Booking = ({ props, isConfirmed, confirmBooking }) => {
  const [bookingConfirmed, setbookingConfirmed] = useState(
    props.bookingConfirmed,
  )

  const handleConfirmBooking = async (bookingId) => {
    try {
      await confirmBooking(props._id)
      setbookingConfirmed(true)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div
      className={`border m-2 p-4 flex ${
        bookingConfirmed ? `border-green-500` : `border-red-500`
      }`}
    >
      <div className="grid grid-cols-2 ">
        <div className="flex justify-start">
          <div className="col-start-1 ">Arrival Date: </div>
          <div>
            <strong>{props.arrivalDate}</strong>
          </div>
        </div>

        <div className="flex">
          <div>Departure Date:</div>
          <div>
            <strong>{props.departureDate}</strong>
          </div>
        </div>

        <div className="flex">
          <div>First name:</div>
          <div>
            {' '}
            <strong>{props.firstName}</strong>
          </div>
        </div>

        <div className="flex">
          <div>last_name: </div>
          <div>
            <strong>{props.lastName}</strong>
          </div>
        </div>

        <div className="flex">
          <div className="flex">
            Booking confirmed:{' '}
            <strong>
              {props.is_confirmed ? <div>true</div> : <div>false</div>}
            </strong>
          </div>
        </div>

        <div>
          <div className="flex">
            Booking is payed:{' '}
            <strong>
              {props.is_payed ? <div>true</div> : <div>false</div>}
            </strong>
          </div>
        </div>

        <div className="flex">
          <div>Number of people: </div>
          <div>
            <strong>{props.nrPeople}</strong>
          </div>
        </div>

        <div className="flex">
          <div>Price of booking:</div>
          <div>
            <strong>{props.price}</strong>
          </div>
        </div>
      </div>
      <div className="ml-auto">
        {!props.bookingConfirmed && (
          <>
            <AiOutlineCheck
              className="mx-3 my-3 w-7 h-7 cursor-pointer"
              onClick={() => handleConfirmBooking(props._id)}
            />
          </>
        )}

        <AiOutlineEdit className="mx-3 my-3 w-7 h-7 cursor-pointer" />
        <AiOutlineDelete className="mx-3 my-3 w-7 h-7 cursor-pointer" />
      </div>
    </div>
  )
}

export default Booking

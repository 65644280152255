import React from 'react'
import mapboxgl from 'mapbox-gl'
import { useEffect, useRef } from 'react';


import 'mapbox-gl/dist/mapbox-gl.css';


const Mapbox = () => {
  const mapContainer = useRef(null);

  useEffect(()=>{
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API;

  const map = new mapboxgl.Map({
    container: mapContainer.current,
    style: 'mapbox://styles/mapbox/streets-v11',
    center: [5.41945034, 43.83265930],
    zoom: 12,
  });

  map.on('load', function() {
    const marker = new mapboxgl.Marker()
      .setLngLat([5.41945034, 43.83265930])
      .addTo(map);
  });

  return () => {
    map.remove();
  };
}, []);

// 43.83265930420983, 5.419450335703744


  return (
    <>
    <div className='flex items-center flex-col py-10'>
      <div ref={mapContainer} className='md:w-1/2 w-3/4 md:h-[500px] h-[300px] border-4 border-provence rounded'/>
    </div>
    </>

  )
}

export default Mapbox

import React from 'react'
import Form from '../components/FormContact'
import { Link } from 'react-router-dom'
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

const Contact = () => {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>Contact the owners of Les Grottes</title>
        <meta
          name="description"
          content="If you have any questions, do not hesitate to contact us by mail, phone, or using this form. We will come back to you shortly."
        />
      </Helmet>
      <div className="mb-24 md:mb-10 lg:mb-24">
        <div className="my-5 lg:my-10 lg:pt-[30px] pt-[60px]">
          <h1 className="font-title text-provence font-semibold text-center text-4xl md:text-6xl lg:text-9xl">
            CONTACT
          </h1>
          <p className="font-text text-center md:text-lg lg:text-2xl">
            {t('contact-questions')}.
          </p>
        </div>

        <div className="font-text flex lg:flex-row flex-col items-center lg:justify-between lg:flex-initial lg:items-start lg:ml-[300px]">
          <div className="">
            <div className="">
              <Form className="" />
            </div>
          </div>

          <div className="flex flex-col lg:items-start items-center py-10 lg:mr-[300px]">
            <div className="py-5">
              <div className="flex w-[180px] md:w-[200px] ">
                <AiOutlinePhone className="m-1 w-8 md:text-3xl" />
                <p className="md:text-lg md:pt-1">{t('number-phone')}</p>
              </div>
            </div>
            <div className="py-5">
              <div className="flex w-[200px] md:w-[250px] ">
                <AiOutlineMail className="m-1 w-8 md:text-3xl" />
                <p className="md:text-lg md:pt-1">contact@lesgrottes.com</p>
              </div>
            </div>
            <div className="my-5 p-2 bg-provence text-white md:text-xl font-bold font-text rounded">
              <Link to="/rent" className="">
                {t('button-rent')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact

import React from 'react'

const Footer = () => {
  return (
    <footer className="font-title bg-white p-4 border-t-[1px] w-full text-center">
      <div className="container mx-auto">
        <div className="flex justify-between">
          <p className="pb-2">© Les Grottes</p>
          <p className="">
            designed by{' '}
            <a
              href="https://www.dos-atelier.com"
              target="_blank"
              className="hover:text-darkGreen"
              rel="noreferrer"
            >
              DOS atelier
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer

import React from 'react'
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import {useTranslation} from 'react-i18next'
import {Helmet} from 'react-helmet'

const Photos = () => {

  const {t} = useTranslation()

  const [slideNumber, setSlideNumber]=useState(0);
  const [open, setOpen]=useState(false)
  const handleOpen = (index)=>{
    setSlideNumber(index);
    setOpen(true);
  }

  const handleMove = (direction)=>{
    let newSlideNumber;
    if(direction === 'l'){
      newSlideNumber = slideNumber === 0 ? 58 : slideNumber - 1
    } else {
      newSlideNumber = slideNumber === 58 ? 0 : slideNumber + 1
    }

    setSlideNumber(newSlideNumber)
  }


  const images = [
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096122/2sallea%CC%80manger_kn2z8u.jpg'
    },
    {
      src: 'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096154/3cuisine_dmwuwi.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096141/4cuisine_kza9ap.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096154/5piscine_hip3b2.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096152/6maison_ahslza.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096156/7truffes_ra4evj.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096143/8sdb_uvpjie.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096180/9chambre_cvmvwn.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096222/10volets_kedfmm.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096175/11chambre_b2beqf.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096208/12terrasse_jsbnn8.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096157/12lavande_mizfzm.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096202/13couloir_ylvujf.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096201/14sdb_dykg66.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096152/15feu_if3hky.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096109/15truffes_z5s2sy.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096138/16dortoir_gj33gd.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096135/17disco_ch8plv.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096141/1d33cf38-43ee-4aa4-be5c-501770e4ffcf_sgbffk.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096153/6c2767d3-9b4f-4f65-b60e-23bb7f51b832_ngkv2j.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096185/IMG_6119_qplcay.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096178/IMG_6139_yfjwpr.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096236/IMG_6189_daomg7.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096218/IMG_6196_r7wyaf.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096239/IMG_6197_lbtvpj.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096208/IMG_6227_rdxwws.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096178/IMG_6269_t0nbyc.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096225/IMG_6309_nmreii.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096245/IMG_6316_az6cxg.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096242/IMG_6326_yl4iqt.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096153/_MG_6131_eeziwe.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096154/aa5c31e0-9086-4574-9912-a65cea83abe4_lwbgks.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096155/d9f909ea-7821-4606-b4be-095c40e6f799_ax3v7g.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096155/e0b72c89-2731-48e8-90ec-c4b3db82d3a3_g3bndo.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096155/f30efc96-cec2-4281-851a-e04d79e01e61_mav2ea.jpg'
    },
    {
      src:'https://res.cloudinary.com/dowjj9cdi/image/upload/v1677096251/mur_hpszx0.jpg'
    }
  ]
  return (
    <>
     <Helmet>
        <title>Photos of our guests enjoying les Grottes</title>
        <meta name='description' content='Photos of the house by the owners so you can look at the facilities, but also photos that our guests sent us. We love seeing them have fun in Les Grottes.'/>
      </Helmet>

    {open && <div className='sticky inset-0 w-screen h-screen bg-black bg-opacity-60 flex items-center z-30'>
      <FontAwesomeIcon
        icon={faXmark}
        className='absolute top-[20px] right-[20px] text-[30px] text-provence cursor-pointer'
        onClick={()=>setOpen(false)}
      />
      <FontAwesomeIcon
        icon={faArrowLeft}
        className='m-[20px] text-[50px] text-provence cursor-pointer'
        onClick={()=>handleMove('l')}
        />
      <div className='h-full w-full flex justify-center items-center'>
        <img src={images[slideNumber].src} alt=":)" className='lg:w-4/5 w-full h-4/5 object-cover' />
      </div>
      <FontAwesomeIcon
      icon={faArrowRight}
      className='m-[20px] text-[50px] text-provence cursor-pointer'
      onClick={()=>handleMove('r')}
      />
    </div>}

      <div className="my-5 lg:my-10 lg:pt-[30px] pt-[60px]">
        <h1 className="font-title text-provence font-semibold text-center text-4xl md:text-6xl lg:text-9xl">
          PHOTOS
        </h1>
        <p className="font-text text-center md:text-lg lg:text-2xl">
          {t('sub-photos')} <span className="font-logo">Les Grottes</span>
        </p>
      </div>


      <div className="photoGallery flex-row flex flex-wrap">
        {images.map((image, index)=>(
          <img
           src={image.src}
           alt="gallery"
           className='w-1/3 h-[300px] object-cover cursor-pointer'
           onClick={()=>handleOpen(index)}
          />
        ))}
      </div>
    </>
  )
}

export default Photos

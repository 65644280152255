import React from 'react'

const Fail = () => {
  return (
    <>
      <div className='min-h-screen flex flex-col justify-center items-center bg-provence'>
      <div className="bg-white rounded-xl w-full md:w-1/2 lg:w-1/3 max-w-md px-8 py-12">
        <p className='font-bold text-center text-xl font-text'>SORRY</p>
        <p className='text-black text-lg text-center mt-5 font-text'>Your payment has been declined.</p>
        <p className='text-center text-lg mt-2 font-text'>Please try again, or contact us for assistance.</p>
        <div className="mt-10 flex justify-center space-x-4">
          <a className='text-center text-provence font-bold hover:text-white hover:bg-provence px-6 py-2 rounded-lg' href='/rent'>Book again</a>
          <a className='text-center text-provence font-bold hover:text-white hover:bg-provence px-6 py-2 rounded-lg' href='/contact'>Contact the host</a>
        </div>
      </div>
    </div>
    </>
  )
}

export default Fail

import React, { useState } from 'react'
import { DateRange } from 'react-date-range'
import { useForm } from 'react-hook-form'
import CalendarV2 from '../components/CalendarV2'
import useFetch from '../hooks/useFetch'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const Rent = () => {
  const [checked, setChecked] = useState(false)
  const { t } = useTranslation()

  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_API_URL}api/houses/dates`,
  )

  const [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
      disabledDay: new Date(),
    },
  ])

  const julySaturdays = [
    new Date('2023-07-01'),
    new Date('2023-07-08'),
    new Date('2023-07-15'),
    new Date('2023-07-22'),
    new Date('2023-07-29'),
    new Date('2024-07-06'),
    new Date('2024-07-13'),
    new Date('2024-07-20'),
    new Date('2024-07-27'),
  ]

  const augustSaturdays = [
    new Date('2023-08-05'),
    new Date('2023-08-12'),
    new Date('2023-08-19'),
    new Date('2023-08-26'),
    new Date('2024-08-03'),
    new Date('2024-08-10'),
    new Date('2024-08-17'),
    new Date('2024-08-24'),
    new Date('2024-08-31'),
  ]

  const minDate = new Date() // booking can not start in the past
  const maxDate = new Date('2024-12-31') // maximum bookable date

  const handleSelect = (ranges) => {
    const selectionStartDate = new Date(ranges.selection.startDate)
    // selectionStartDate.setHours(12)
    const selectionEndDate = new Date(ranges.selection.endDate)
    // selectionEndDate.setHours(12)
    if (selectionEndDate.getDay() !== selectionStartDate.getDay()) {
      const timeDiff = Math.abs(
        selectionEndDate.getTime() - selectionStartDate.getTime(),
      )
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24))

      if (diffDays < 3) {
        alert('Please select minimum 3 nights.')
        return
      }
    }

    if (
      selectionStartDate.getMonth() === 6 ||
      selectionStartDate.getMonth() === 7
    ) {
      // if booking start date is in July or August
      if (
        !(
          julySaturdays.some(
            (date) => date.toDateString() === selectionStartDate.toDateString(),
          ) ||
          augustSaturdays.some(
            (date) => date.toDateString() === selectionStartDate.toDateString(),
          )
        )
      ) {
        alert(t('alert'))
        return
      }

      // check if the selected end date is a Saturday in July or August and is after the start date
      if (
        !(
          julySaturdays.some(
            (date) => date.toDateString() === selectionEndDate.toDateString(),
          ) ||
          augustSaturdays.some(
            (date) => date.toDateString() === selectionEndDate.toDateString(),
          )
        ) ||
        selectionEndDate < selectionStartDate
      ) {
        alert('Please select a valid end date.')
        return
      }
    } else {
      // if booking start date is not in July or August
      if (selectionEndDate < selectionStartDate) {
        alert('Please select a valid end date.')
        return
      }
    }

    setDates([ranges.selection])
  }

  const { register, handleSubmit, reset } = useForm()

  const MILLISECONDS_PER_DAY = 1000 * 60 * 60 * 24

  const dayDifference = (date1, date2) => {
    const timeDiff = Math.abs(date2.getTime() - date1.getTime())
    const diffDays = Math.ceil(timeDiff / MILLISECONDS_PER_DAY)
    return diffDays
  }

  const disabledDates = data.map((date) => new Date(date))

  // Get all the unavailable dates
  const expandedDates = []

  for (let i = 0; i < disabledDates.length; i += 2) {
    const startDate = disabledDates[i]
    const endDate = disabledDates[i + 1]

    if (endDate) {
      const daysBetween = Math.round(
        (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24),
      )
      for (let j = 0; j <= daysBetween; j++) {
        const newDate = new Date(startDate.getTime() + j * 24 * 60 * 60 * 1000)
        if (j !== 0 && j !== daysBetween) {
          expandedDates.push(newDate)
        }
      }
    } else {
      expandedDates.push(startDate)
    }
  }

  // Check dates from start to end and in between
  const computeSelectedDates = () => {
    const startDate = new Date(dates[0].startDate)
    const endDate = new Date(dates[0].endDate)
    const days = Math.floor((endDate - startDate) / (24 * 60 * 60 * 1000)) + 1

    const dateArray = Array.from({ length: days }, (_, index) => {
      const currentDate = new Date(startDate)
      currentDate.setDate(startDate.getDate() + index)
      return currentDate.toISOString()
    })
    return dateArray
  }

  const selectedDates = computeSelectedDates()

  // Check if the date is a High Season date
  const isHighSeason = (date) => {
    const highSeasonStart = new Date(date.getFullYear(), 6, 1)
    const highSeasonEnd = new Date(date.getFullYear(), 7, 31)

    return date >= highSeasonStart && date <= highSeasonEnd
  }

  // Calculate the price of a day based on High/Low Season
  const calculatePrices = (dates) => {
    const lowSeasonPrice = 375
    const highSeasonPrice = 550
    let prices = []

    for (let i = 0; i < dates.length; i++) {
      const date = new Date(dates[i])
      const highSeason = isHighSeason(date)

      if (highSeason) {
        prices.push(highSeasonPrice)
      } else {
        prices.push(lowSeasonPrice)
      }
    }

    if (prices.length > 1) {
      prices.pop()
    }
    return prices
  }

  const price = calculatePrices(selectedDates).reduce(
    (accumulator, currentValue) => accumulator + currentValue,
  )

  const navigate = useNavigate()

  const onSubmit = (input) => {
    const bookingData = getBookingData(input)
    // IF CHECKBOX IS CHECHED -> INCREASE PRICE
    if (checked) {
      bookingData.price += 30 * bookingData.nrPeople
    }
    navigate('/pay', {
      state: { bookingData: bookingData },
    })
    reset()
  }

  const getBookingData = (input) => {
    let bookingData = {
      arrivalDate: new Date(dates[0].startDate).setHours(12),
      departureDate: new Date(dates[0].endDate).setHours(13),
      price: price + 180 + 500,
      firstName: input.firstName,
      lastName: input.lastName,
      email: input.email,
      phoneNumber: input.phoneNumber,
      nrPeople: parseInt(input.nrPeople),
      bookingConfirmed: false,
      paymentConfirmed: false,
      IBAN: input.IBAN,
      payment_intent: '',
    }
    return bookingData
  }

  return (
    <div>
      <Helmet>
        <title>Rent this house in the South of France</title>
        <meta
          name="description"
          content="It can be settled in only a few clicks. Don't miss the chance to spend a week in this house with family or friends."
        />
      </Helmet>
      <div className="my-5 lg:my-10 lg:pt-[30px] pt-[60px]">
        <h1 className="font-title text-provence font-semibold text-center text-4xl md:text-6xl lg:text-9xl">
          {t('title-rent')}
        </h1>
        <p className="font-text text-center md:text-lg lg:text-2xl">
          {t('sub-rent')}
        </p>
      </div>

      <p className="font-text text-center object-center pb-5 lg:text-lg">
        {t('high-low')}
      </p>

      <div className="flex flex-col items-center lg:items-start lg:flex-row lg:pb-[35px] lg:pl-[110px]">
        <div className=" lg:w-1/3 h-[388px] lg:ml-auto lg:mr-auto lg:justify-center lg:flex ">
          {expandedDates.length !== 0 ? (
            <div>
              {' '}
              <DateRange
                // You can not book in the past minDate
                minDate={minDate}
                maxDate={maxDate}
                editableDateInputs={true}
                onChange={handleSelect}
                moveRangeOnFirstSelection={false}
                ranges={dates}
                disabledDates={expandedDates}
                // rangeColors={['#f33e5b', '#3ecf8e', '#fed14c']}

                className="border-4 border-provence rounded"
              />
            </div>
          ) : (
            <div className="font-text">Loading dates...</div>
          )}
        </div>

        <div className="lg:w-2/3 justify-start">
          <form
            className=" h-full w-[350px] lg:w-[700px] lg:h-100 lg:p-5 border-4 rounded border-provence"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="lg:grid lg:grid-cols-1 lg:grid-rows-3 gap-4">
              <div className="row-start-1 text-center mt-4 ">
                <input
                  type="text"
                  placeholder={t('first-name')}
                  name="firstName"
                  className="border w-[250px]"
                  {...register('firstName', { required: true })}
                />
              </div>
              <div className="row-start-2 text-center mt-4">
                <input
                  className="border w-[250px]"
                  type="text"
                  placeholder={t('last-name')}
                  name="lastName"
                  {...register('lastName', { required: true })}
                />
              </div>
              <div className="row-start-3 text-center mt-4">
                <input
                  className="border w-[250px]"
                  type="email"
                  placeholder="email"
                  name="email"
                  {...register('email', { required: true })}
                />
              </div>
              <div className="row-start-1 text-center mt-4">
                <input
                  className="border w-[250px]"
                  type="text"
                  placeholder={t('phone-number')}
                  name="phoneNumber"
                  {...register('phoneNumber', { required: true })}
                />
              </div>

              <div className="row-start-2 text-center mt-4">
                <input
                  type="number"
                  placeholder={t('number')}
                  name="nrPeople"
                  className="border w-[250px]"
                  min="1"
                  max="23"
                  {...register('nrPeople', { required: true })}
                />
              </div>
              <div className="row-start-3 text-center mt-4">
                <input
                  type="string"
                  placeholder={t('IBAN')}
                  name="IBAN"
                  className="border w-[250px]"
                  {...register('IBAN', { required: true })}
                />
              </div>
            </div>

            <p className="lg:text-start text-center row-start-4 row-span-3 pt-5 font-text mx-3 lg:ml-10 mt-4">
              <strong>
                {t('price')} : {price} €
              </strong>
              <br />
              {t('included')}
              <div className="mt-5">
                <input
                  type="checkbox"
                  text="sheets"
                  onChange={() => setChecked(true)}
                />
                <label className="ml-2">
                  Sheets €30/person (if not selected, bring your own sheets)
                </label>
              </div>
            </p>

            <div className="row-start-6 mx-5 text-center col-span-2 justify-center my-5 bg-provence py-1 px-3 rounded-lg text-white font-bold hover:bg-blue-400">
              <input
                type="submit"
                value={t('proceed')}
                // onClick={handleButtonClick}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="text-justify font-text lg:pr-[80px] lg:pl-[130px] pr-[20px] pl-[20px] pt-10 lg:pt-2 pb-5">
        <p className="font-bold">{t('check-in-time')}</p>
        <p>{t('check-in')}</p>
        <p className="font-bold">{t('reimbursement-politics')}</p>
        <p>{t('reimbursement')}</p>
      </div>

      {/* <button className="p-5 bg-green-500 text-white" onClick={handlePayment}>
        Pay Now
      </button> */}
    </div>
  )
}

export default Rent

import React, { useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import CheckoutForm from '../components/CheckoutForm.jsx'
import { useTranslation } from 'react-i18next'
import api from '../api.js'

const stripePromise = loadStripe(
  'pk_live_51MoOeiG348jbETdGzOQHn99mTlHnTiigZPXJOmAR4sYgOdLWsYhqnQx09i6oFvclT4mDOmQ8PjxtU4zwe5z5LEnv00vYCjMWoP',
)

const Pay = (props) => {
  const [clientSecret, setClientSecret] = useState('')
  const { t } = useTranslation()

  const {
    state: { bookingData },
  } = useLocation()

  const navigate = useNavigate()

  useEffect(() => {
    const makeRequest = async () => {
      try {
        const res = await api.post(
          `${process.env.REACT_APP_API_URL}api/payments/create-payment-intent`,
          bookingData,
        )
        const clientSecret = res.data.clientSecret

        setClientSecret(clientSecret)
      } catch (error) {
        console.log(error)
      }
    }
    makeRequest()
  }, [])

  const appearance = {
    theme: 'stripe',
  }
  const options = {
    clientSecret,
    appearance,
  }

  // POP UP CODE
  const [accepted, setAccepted] = useState(false)

  const handleAccept = (event) => {
    setAccepted(event.target.checked)
  }

  const [showPopup, setShowPopup] = useState(true)

  const handleButtonClick = () => {
    setShowPopup(false)
  }

  const handlePopupClose = () => {
    setShowPopup(false)
  }

  return (
    <div className="App mt-20 mb-[180px]">
      <div className="row-start-6 mx-5 text-center col-span-2 justify-center px-3 rounded-lg text-white font-bold">
        {showPopup && (
          <>
            <div className="fixed inset-0 z-50 flex items-center justify-center">
              <div className="fixed inset-0 bg-gray-900 opacity-50"></div>
              <div className="h-[450px] md:h-auto overflow-scroll	 bg-white p-8 w-4/5 rounded-lg shadow-lg z-10">
                <div className="flex justify-end">
                  {/* <button
                    className="bg-white rounded-full p-1 hover:bg-gray-200"
                    onClick={handlePopupClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button> */}
                </div>
                <h2 className="text-lg font-medium mb-4 font-text text-provence">
                  {t('house-rules')}
                </h2>
                <p className="mb-6 text-slate-800 font-light font-text">
                  {t('rule-0')}
                </p>
                <p className="mb-6 text-slate-800 font-light font-text">
                  {t('rule-1')}
                </p>
                <p className="mb-6 text-slate-800 font-light font-text">
                  {t('rule-2')}
                </p>
                <p className="mb-6 text-slate-800 font-light font-text">
                  {t('rule-3')}
                </p>
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-gray-600"
                    checked={accepted}
                    onChange={handleAccept}
                  />
                  <span className="ml-2 text-gray-700 text-sm">
                    {t('tick')}
                  </span>
                </label>
                <button
                  className="ml-auto block bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                  disabled={!accepted}
                  onClick={handleButtonClick}
                >
                  Next
                </button>
              </div>
            </div>
            <div className="fixed inset-0 z-40"></div>
          </>
        )}
      </div>

      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm props={bookingData} />
        </Elements>
      )}
    </div>
  )
}

export default Pay

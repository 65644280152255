import React from "react";

import { useState, useEffect } from "react";
import api from "../api";

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await api.get(url);
        setData(res.data);
      } catch (error) {
        throw error;
      }
      setLoading(false);
    };
    fetchData();
  }, [url]);

  const reFetch = async () => {
    setLoading(true);
    try {
      const res = api.get(url);
      setData(res.data);
    } catch (error) {
      throw error;
    }
  };
  return { data, loading, error, reFetch };
};

export default useFetch;

import React from 'react'
import SiverguesVillage from '../images/siverguesVillage.jpeg'
import Apt from '../images/Apt.avif'
import Saignon from '../images/Saignon.avif'
import Bonnieux from '../images/Bonnieux.avif'
import Lourmarin from '../images/Lourmarin.avif'
import IsleSurLaSorgue from '../images/isls.avif'
import Roussillon from '../images/roussillon.avif'
import MourreNegre from '../images/mourrenegre.jpg'
import GR97 from '../images/GR97.jpeg'
import Ventoux from '../images/montventoux.avif'
import SaintTrop from '../images/SaintTrop.jpeg'
import Avignon from '../images/avignon.avif'
import Marseille from '../images/marseille.avif'
import { useTranslation } from 'react-i18next'
import {Helmet} from 'react-helmet'
import Accrobranche from '../images/accrobranche.jpeg'
import Lavander from '../images/lavander.avif'
import Kayak from '../images/kayak.avif'
import Karting from '../images/karting.avif'
const Sivergues = () => {
  const {t} = useTranslation()
  return (
  <>
   <Helmet>
        <title>Surroundings of the house : what to do in the area</title>
        <meta name='description' content='Here is a little blog provided by the owners with their favorite things to do when they come visit.'/>
    </Helmet>

  <div className='my-5 lg:my-10 lg:pt-[30px] pt-[60px]'>
    <h1 className='font-title text-provence font-semibold text-center text-4xl md:text-6xl lg:text-9xl'>SIVERGUES</h1>
    <p className='font-text text-center md:text-lg lg:text-2xl'>{t('sub-surr')}</p>
  </div>

<div className='grid grid-cols-12 grid-rows-1 gap-3 mb-10'>
  <div className='row-start-1 col-start-2 col-span-10 md:col-start-3 md:col-span-8 text-justify font-title text-lg'>
    <p className='font-text text-xs md:text-base lg:text-lg'>
      <span className='font-logo text-sm md:text-lg lg:text-xl'>Les Grottes</span> {t('text-surr-1')}</p>
    <p className='font-text text-xs md:text-base lg:text-lg'> {t('text-surr-2')} </p>
  </div>
</div>

<div className='grid grid-cols-11 grid-rows-3 md:grid-rows-2 gap-3 pt-5 mb-10'>
  <div className='col-start-3 sm:col-start-4 md:col-start-3 col-span-7 row-start-1 row-span-1'>
    <img src={SiverguesVillage} alt="Sivergues' village" className='' />
    <p className='italic font-thin text-xs'> Stéphanie PAGNIER - CC BY-SA 3.0</p>
  </div>
  <div className='col-start-3 col-span-7 row-start-2 md:row-start-1 md:col-start-7 lg:col-start-6'>
    <h3 className='font-title font-semibold text-center text-lg lg:text-xl pt-4 md:mt-[-14px] lg:mt-[-25px] xl:mt-[-10px] md:pt-0 md:text-start text-provence'>{t('village-sivergues')}</h3>
  </div>
  <div className='row-start-2 md:row-start-1 md:col-start-7 mt-9 md:mt-[-7px] lg:mt-[-21px] xl:mt-0 row-span-2 col-start-2  lg:col-start-6 col-span-9 md:col-span-3 lg:col-span-4  text-justify pt-7'>
    <p className='font-text text-xs lg:text-base'>
      {t('desc-sivergues')}
      <br />
      {t('desc-sivergues-2')}
    </p>
  </div>
  <div className='row-start-3 md:row-start-2 col-start-2 md:col-start-3 col-span-9 md:col-span-7 md:mt-5'>
    <p className='text-start font-title font-semibold pb-4'> </p>
    <p className='font-title text-sm mt-[-20px] lg:text-lg'>Le Castellas</p>
    <p className='font-text text-xs lg:text-base text-justify pb-3'>
      {t('castellas')}
    </p>
    <p className='font-title text-sm lg:text-lg'>{t('hike')}</p>
    <p className='font-text text-xs lg:text-base text-justify '>
     {t('hike-sivergues')}
    </p>
  </div>
</div>

<div className='grid grid-cols-12 grid-rows-1'>
<hr className='col-start-3 col-end-11'/>
</div>

<div className = 'grid grid-cols-12 grid-rows-1 gap-3 pt-10 mb-10'>
    <h3 className='row-start-1 col-start-2 col-span-10 text-center font-title text-provence font-bold text-lg md:text-xl lg:text-2xl xl:text-3xl'>{t('bonnes-addresses')}</h3>
</div>

<div className='grid grid-rows-6 md:grid-rows-6 lg:grid-rows-4 grid-cols-12 gap-5 pt-5 mb-10'>
    <div className='row-start-1 col-start-3 col-span-8 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-2 object-contain'>
      <img  src={Lavander} alt='apt' className='object-cover h-[300px]'/>

    </div>
    <div className='row-start-2 text-center col-start-2 col-span-10 md:col-start-3 md:col-end-6 lg:col-start-2 lg:col-end-5 font-title text-provence'>
      <h4 className='lg:text-xl'>
        {t('bike-rental')}
      </h4>
    </div>
    <div className='row-start-2 row-span-2 text-justify col-start-3 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-2 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'> {t("bike-desc-1")} </p>
      <p className='font-text text-xs lg:text-sm'> {t('bike-desc-2')} </p>
      <a href='https://www.stationsbees.com/stations/apt-luberon-nord-ouverture-31-mars' className='font-text text-provence underline hover:font-bold text-xs lg:text-sm hover:no-underline'>website</a>
    </div>

    <div className='row-start-3 md:row-start-1 col-start-3 col-span-8 md:col-start-7 md:col-span-5 lg:col-span-4 lg:col-start-5 lg:mx-12 object-contain'>
      <img src={Kayak} alt="saignon" className=' h-[300px] w-full object-cover' />
    </div>
    <div className='row-start-4 md:row-start-2 text-center col-start-2 col-span-10 md:col-start-7 md:col-end-12 lg:col-start-5 lg:col-end-9 font-title text-provence'>
      <h4 className='lg:text-lg'>
        {t('kayak-rental')}
      </h4>
    </div>
    <div className='row-start-4 md:row-start-2 row-span-2 text-justify col-start-3 md:col-start-7 md:col-span-5 lg:col-span-4 lg:col-start-5 lg:mx-12 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'> {t('desc-kayak-1')} </p>
      <p className='font-text text-xs lg:text-sm'>{t('desc-kayak-2')}</p>
      <a href='http://www.ccki.fr/index.html?1' className='font-text text-provence underline hover:font-bold text-xs lg:text-sm hover:no-underline'>website</a>
    </div>

    <div className='row-start-5 md:row-start-3 lg:row-start-1 col-start-3 col-span-8 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-9 object-contain'>
    <img src={Accrobranche} alt="saignon" className='object-cover h-[300px]' />
    </div>
    <div className='row-start-6 md:row-start-4 lg:row-start-2 text-center col-start-2 col-span-10 md:col-start-3 md:col-end-6 lg:col-start-9 lg:col-end-12 font-title text-provence'>
      <h4 className='lg:text-lg'>
        Accrobranche
      </h4>
    </div>
    <div className='row-start-6 md:row-start-4 lg:row-start-2 row-span-2 text-justify col-start-3 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-9 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'> {t('desc-accro-1')}</p>
      <p className='font-text text-xs lg:text-sm'> {t('desc-accro-2')}</p>
      <a href='https://coloradoaventures.com/' className='font-text text-provence underline hover:font-bold text-xs lg:text-sm hover:no-underline'>website</a>
    </div>

    <div className='row-start-7 md:row-start-3 col-start-3 col-span-8 md:col-start-7 md:col-span-5 lg:col-span-3 lg:col-start-2 object-contain'>
    <img src={Karting} alt="saignon" className='object-cover h-[300px]' />
    </div>
    <div className='row-start-8 md:row-start-4 text-center col-start-2 col-span-10 md:col-start-7 md:col-end-12 lg:col-start-2 lg:col-end-5 font-title text-provence'>
      <h4 className='lg:text-lg'>
        Karting
      </h4>
    </div>
    <div className='row-start-8 md:row-start-4 row-span-2 text-justify col-start-3 md:col-start-7 md:col-span-5 lg:col-span-3 lg:col-start-2 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'> {t('desc-karting-1')} </p>
      <p className='font-text text-xs lg:text-sm'>{t('desc-karting-2')}</p>
      <a href='https://www.kartingmanosque.fr/' className='font-text text-provence underline hover:font-bold text-xs lg:text-sm hover:no-underline'>website</a>
    </div>
</div>

<div className='grid grid-cols-12 grid-rows-1'>
<hr className='col-start-3 col-end-11'/>
</div>

<div className = 'grid grid-cols-12 grid-rows-1 gap-3 pt-10 mb-10'>
    <h3 className='row-start-1 col-start-2 col-span-10 text-center font-title text-provence font-bold text-lg md:text-xl lg:text-2xl xl:text-3xl'>{t('villages')}</h3>
</div>

<div className='grid grid-rows-6 md:grid-rows-6 lg:grid-rows-4 grid-cols-12 gap-5 pt-5 mb-10'>
    <div className='row-start-1 col-start-3 col-span-8 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-2 object-contain'>
      <img  src={Apt} alt='apt' className='object-cover h-[300px]'/>

    </div>
    <div className='row-start-2 text-center col-start-2 col-span-10 md:col-start-3 md:col-end-6 lg:col-start-2 lg:col-end-5 font-title text-provence'>
      <h4 className='lg:text-xl'>
        Apt
      </h4>
    </div>
    <div className='row-start-2 row-span-2 text-justify col-start-3 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-2 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'>{t('desc-apt-1')} </p>
      <p className='font-text text-xs lg:text-sm'>{t('desc-apt-2')}</p>
      <p className='font-text font-bold text-xs lg:text-sm'>{t('market-apt')}</p>
    </div>

    <div className='row-start-3 md:row-start-1 col-start-3 col-span-8 md:col-start-7 md:col-span-5 lg:col-span-4 lg:col-start-5 lg:mx-12 object-contain'>
      <img src={Saignon} alt="saignon" className='object-cover h-[300px]' />
    </div>
    <div className='row-start-4 md:row-start-2 text-center col-start-2 col-span-10 md:col-start-7 md:col-end-12 lg:col-start-5 lg:col-end-9 font-title text-provence'>
      <h4 className='lg:text-lg'>
        Saignon
      </h4>
    </div>
    <div className='row-start-4 md:row-start-2 row-span-2 text-justify col-start-3 md:col-start-7 md:col-span-5 lg:col-span-4 lg:col-start-5 lg:mx-12 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'>{t('desc-saignon-1')} </p>
      <p className='font-text text-xs lg:text-sm'>{t('desc-saignon-2')}</p>
    </div>

    <div className='row-start-5 md:row-start-3 lg:row-start-1 col-start-3 col-span-8 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-9 object-contain'>
    <img src={Bonnieux} alt="saignon" className='object-cover h-[300px]' />
    </div>
    <div className='row-start-6 md:row-start-4 lg:row-start-2 text-center col-start-2 col-span-10 md:col-start-3 md:col-end-6 lg:col-start-9 lg:col-end-12 font-title text-provence'>
      <h4 className='lg:text-lg'>
        Bonnieux
      </h4>
    </div>
    <div className='row-start-6 md:row-start-4 lg:row-start-2 row-span-2 text-justify col-start-3 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-9 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'> {t('desc-bonnieux-1')} </p>
      <p className='font-text text-xs lg:text-sm'> {t('desc-bonnieux-2')} </p>
      <p className='font-text font-bold text-xs lg:text-sm'> {t('market-bonnieux')}</p>
    </div>

    <div className='row-start-7 md:row-start-3 col-start-3 col-span-8 md:col-start-7 md:col-span-5 lg:col-span-3 lg:col-start-2 object-contain'>
    <img src={Lourmarin} alt="saignon" className=' w-full object-cover h-[300px]' />
    </div>
    <div className='row-start-8 md:row-start-4 text-center col-start-2 col-span-10 md:col-start-7 md:col-end-12 lg:col-start-2 lg:col-end-5 font-title text-provence'>
      <h4 className='lg:text-lg'>
        Lourmarin
      </h4>
    </div>
    <div className='row-start-8 md:row-start-4 row-span-2 text-justify col-start-3 md:col-start-7 md:col-span-5 lg:col-span-3 lg:col-start-2 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'> {t('desc-lourmarin')} </p>
      <p className='font-text text-xs lg:text-sm font-bold'>{t('market-lourmarin')}</p>
    </div>

    <div className='row-start-9 md:row-start-5 lg:row-start-3 col-start-3 col-span-8 md:col-start-2 md:col-span-5 lg:col-span-4 lg:col-start-5 lg:mx-12 object-contain'>
    <img src={IsleSurLaSorgue} alt="saignon" className='object-cover h-[300px] w-full' />
    </div>
    <div className='row-start-10 md:row-start-6 lg:row-start-4 text-center col-start-2 col-span-10 md:col-start-3 md:col-end-6 lg:col-start-5 lg:col-end-9 font-title text-provence'>
      <h4 className='lg:text-lg'>
        Isle sur la Sorgue
      </h4>
    </div>
    <div className='row-start-10 md:row-start-6 lg:row-start-4 row-span-2 text-justify col-start-3 md:col-start-2 md:col-span-5 lg:col-span-4 lg:col-start-5 lg:mx-12 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'> {t('desc-isls-1')} </p>
      <p className='font-text text-xs lg:text-sm'>{t('desc-isls-2')}</p>
      <p className='font-text font-bold text-xs lg:text-sm'>{t('market-isls')}</p>
    </div>
</div>

<div className='grid grid-cols-12 grid-rows-1'>
  <hr className='col-start-3 col-end-11'/>
</div>

<div className = 'grid grid-cols-12 grid-rows-1 gap-3 pt-10 mb-10'>
    <h3 className='row-start-1 col-start-2 col-span-10 text-center font-title text-provence font-bold text-lg md:text-xl lg:text-2xl xl:text-3xl'> {t('sub-hike')} </h3>
</div>

<div className='grid grid-rows-6 md:grid-rows-4 lg:grid-rows-4 grid-cols-12 gap-5 pt-5 mb-10'>
    <div className='row-start-1 col-start-3 col-span-8 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-2 object-contain'>
    <img src={Roussillon} alt="saignon" className='object-cover h-[300px]' />
    </div>
    <div className='row-start-2 text-center col-start-2 col-span-10 md:col-start-3 md:col-end-6 lg:col-start-2 lg:col-end-5 font-title text-provence'>
      <h4 className='lg:text-lg'>
        The Ochre Trail
      </h4>
    </div>
    <div className='row-start-2 row-span-2 text-justify col-start-3 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-2 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'>{t('desc-ochre-1')}</p>
      <p className='font-text text-xs lg:text-sm'>{t('desc-ochre-2')}</p>
    </div>
    <div className='row-start-3 md:row-start-1 col-start-3 col-span-8 md:col-start-7 md:col-span-5 lg:col-span-4 lg:col-start-5 lg:mx-12 object-contain'>
    <img src={MourreNegre} alt="saignon" className='object-cover h-[300px] w-full' />
    <p className='text-xs font-thin italic'>Marchall — CC BY-SA 3.0</p>
    </div>
    <div className='row-start-4 md:row-start-2 text-center col-start-2 col-span-10 md:col-start-7 md:col-end-12 lg:col-start-5 lg:col-end-9 font-title text-provence'>
      <h4 className='lg:text-lg'>
        Mourre Nègre
      </h4>
    </div>
    <div className='row-start-4 md:row-start-2 row-span-2 text-justify col-start-3 md:col-start-7 md:col-span-5 lg:col-span-4 lg:col-start-5 lg:mx-12 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'>{t('desc-mourre-1')} </p>
      <p className='font-text text-xs lg:text-sm'>{t('desc-mourre-2')} </p>
    </div>
    <div className='row-start-5 md:row-start-3 lg:row-start-1 col-start-3 col-span-8 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-9 object-contain'>
    <img src={GR97} alt="saignon" className='object-cover h-[300px]' />
    </div>
    <div className='row-start-6 md:row-start-4 lg:row-start-2 text-center col-start-2 col-span-10 md:col-start-3 md:col-end-6 lg:col-start-9 lg:col-end-12 font-title text-provence'>
      <h4 className='lg:text-lg'>
        GR97
      </h4>
    </div>
    <div className='row-start-6 md:row-start-4 lg:row-start-2 row-span-2 text-justify col-start-3 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-9 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'>{t('desc-GR-1')} </p>
      <p className='font-text text-xs lg:text-sm'> {t('desc-GR-2')} </p>
    </div>
    <div className='row-start-7 md:row-start-3 col-start-3 col-span-8 md:col-start-7 md:col-span-5 lg:col-span-3 lg:col-start-2 object-contain'>
    <img src={Ventoux} alt="saignon" className='object-cover h-[300px] w-full' />
    </div>
    <div className='row-start-8 md:row-start-4 text-center col-start-2 col-span-10 md:col-start-7 md:col-end-12 lg:col-start-2 lg:col-end-5 font-title text-provence'>
      <h4 className='lg:text-lg'>
        Mont Ventoux
      </h4>
    </div>
    <div className='row-start-8 md:row-start-4 row-span-2 text-justify col-start-3 md:col-start-7 md:col-span-5 lg:col-span-3 lg:col-start-2 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm' > {t('desc-ventoux-1')} </p>
      <p className='font-text text-xs lg:text-sm'> {t('desc-ventoux-2')} </p>
    </div>
</div>

<div className='grid grid-cols-12 grid-rows-1'>
  <hr className='col-start-3 col-end-11'/>
</div>

<div className = 'grid grid-cols-12 grid-rows-1 gap-3 pt-10 mb-10'>
    <h3 className='row-start-1 col-start-2 col-span-10 text-center font-title text-provence font-bold text-lg md:text-xl lg:text-2xl xl:text-3xl'>{t('sub-others')}</h3>
</div>

<div className='grid grid-rows-6 md:grid-rows-4 lg:grid-rows-2 grid-cols-12 gap-5 pt-5 mb-20'>
    <div className='row-start-1 col-start-3 col-span-8 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-2 object-contain'>
    <img src={SaintTrop} alt="saignon" className='object-cover w-full h-[300px]' />
    </div>
    <div className='row-start-2 text-center col-start-2 col-span-10 md:col-start-3 md:col-end-6 lg:col-start-2 lg:col-end-5 font-title text-provence'>
      <h4 className='lg:text-lg'>
        Saint Tropez
      </h4>
    </div>
    <div className='row-start-2 row-span-2 text-justify col-start-3 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-2 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'> {t('desc-saintrop-1')} </p>
      <p className='font-text text-xs lg:text-sm'> {t('desc-saintrop-2')}</p>
    </div>
    <div className='row-start-3 md:row-start-1 col-start-3 col-span-8 md:col-start-7 md:col-span-5 lg:col-span-4 lg:col-start-5 lg:mx-12 object-contain'>
    <img src={Avignon} alt="saignon" className='object-cover h-[300px]' />
    </div>
    <div className='row-start-4 md:row-start-2 text-center col-start-2 col-span-10 md:col-start-7 md:col-end-12 lg:col-start-5 lg:col-end-9 font-title text-provence'>
      <h4 className='lg:text-lg'>
        Avignon
      </h4>
    </div>
    <div className='row-start-4 md:row-start-2 row-span-2 text-justify col-start-3 md:col-start-7 md:col-span-5 lg:col-span-4 lg:col-start-5 lg:mx-12 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'> {t('desc-avignon-1')} </p>
      <p className='font-text text-xs lg:text-sm'> {t('desc-avignon-2')} </p>
    </div>
    <div className='row-start-5 md:row-start-3 lg:row-start-1 col-start-3 col-span-8 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-9 object-contain'>
    <img src={Marseille} alt="saignon" className='object-cover h-[300px]' />
    </div>
    <div className='row-start-6 md:row-start-4 lg:row-start-2 text-center col-start-2 col-span-10 md:col-start-3 md:col-end-6 lg:col-start-9 lg:col-end-12 font-title text-provence'>
      <h4 className='lg:text-lg'>
        Marseille
      </h4>
    </div>
    <div className='row-start-6 md:row-start-4 lg:row-start-2 row-span-2 text-justify col-start-3 md:col-start-2 md:col-span-5 lg:col-span-3 lg:col-start-9 col-span-8 mt-7'>
      <p className='font-text text-xs lg:text-sm'> {t('desc-marseille-1')}</p>
      <p className='font-text text-xs lg:text-sm'> {t('desc-marseille-2')} </p>
    </div>
</div>

    </>
  )
}

export default Sivergues

import i18next from 'i18next';
import backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

const lang = localStorage.getItem('lang') || 'en'

i18next
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: lang, // set the default language to French
    fallbackLng: 'en', // use English as the fallback language
    debug: true, // enable debug mode
    backend: {
      loadPath: 'assets/locales/{{lng}}/{{ns}}.json' // specify the path to the translation files
    },
    ns: ['translation'], // specify the namespace for the translations
    defaultNS: 'translation', // set the default namespace
    preload: ['fr', 'nl', 'en'], // preload the French and Dutch translations
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  });

export default i18next;
